import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  formatRfcTimeStringToDateAndTimeView,
  getDateTimeFromLocalDateTime,
} from '../../../../utils/dateAndTime/dateAndTime';
import { getBookingPreferencesForSelectedTime } from '../../../../utils/bookingPreferences/bookingPreferencesForSelectedTime';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState, TFunction } from '../../controller';
import {
  BookingPreference,
  BookingPreferenceOption,
} from '../../../../utils/bookingPreferences/bookingPreferences';
import { MemoizedViewModalFactory } from '../viewModel';
import { CalendarErrors, Preference } from '../../../../types/types';
import { getFurthestValidUntilPlan } from '../../../../utils/pricingPlans/pricingPlans';
import { getPaymentDescription } from '../../../../utils/payment/payment';
import { DO_NOT_CARE_STAFF } from '../../../../constants/constants';
import { canAutoAssignStaff } from '../../../../utils/serviceUtils/serviceUtils';

export type BookingDetailsPreferences = {
  bookingPreferences: BookingPreference[];
  titleText: string;
  clearText: string;
};

export type BookingDetailsViewModel = {
  serviceName: string;
  dateAndTime?: string;
  paymentDescription?: string;
  videoConferenceBadgeText?: string;
  preferences?: BookingDetailsPreferences;
  alert?: string;
};

export const memoizedBookingDetailsViewModel: MemoizedViewModalFactory<BookingDetailsViewModel> =
  {
    dependencies: {
      state: [
        'servicesInView',
        'serviceVariantsMap',
        'selectableSlotsAtSelectedTime',
        'selectedTime',
        'selectedBookingPreferences',
        'calendarErrors',
        'purchasedPricingPlans',
        'selectedVariantsOptions',
      ],
      settings: [
        'videoConferenceBadgeVisibility',
        'videoConferenceBadge',
        'bookingDetailsPricingPlanText',
        'waitlistIndication',
        'bookingDetailsClearText',
        'preferencesTitle',
        'nextButton',
        'locationLabel',
        'staffMemberLabel',
        'durationLabel',
      ],
      subDependencies: [],
    },
    createViewModel: createBookingDetailsViewModel,
  };

const getVideoConferencingBadge = (context: CalendarContext) => {
  const { getContent, settingsParams } = context;
  return getContent({
    settingsParam: settingsParams.videoConferenceBadge,
    translationKey: 'app.settings.defaults.video-conference-badge-text',
  });
};

export function createBookingDetailsViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): BookingDetailsViewModel {
  const {
    businessInfo,
    t,
    settings,
    settingsParams,
    getContent,
    experiments,
    isDateAndTimeViewMode,
  } = context;
  const dateRegionalSettingsLocale = businessInfo!.dateRegionalSettingsLocale!;
  const {
    servicesInView,
    selectableSlotsAtSelectedTime,
    selectedTime,
    selectedBookingPreferences,
    calendarErrors,
    purchasedPricingPlans,
    selectedVariantsOptions,
  } = state;

  const serviceName = servicesInView[0].info.name;

  let alert;
  if (calendarErrors.includes(CalendarErrors.NO_VALID_PRICING_PLAN_WARNING)) {
    const furthestValidUntilPlan = getFurthestValidUntilPlan(
      purchasedPricingPlans,
    );
    alert = furthestValidUntilPlan
      ? t('app.booking-details.pricing-plan-alert', {
          planName: furthestValidUntilPlan.planName,
          planDate: getDateTimeFromLocalDateTime(
            furthestValidUntilPlan.validUntil!,
          ),
        })
      : undefined;
  }
  const shouldShowVideoConferenceBadge = settings.get(
    settingsParams.videoConferenceBadgeVisibility,
  );
  const isServiceOfferedOnline = servicesInView[0].includeConferenceOption;

  const videoConferenceBadgeText =
    shouldShowVideoConferenceBadge && isServiceOfferedOnline
      ? getVideoConferencingBadge(context)
      : '';

  const paymentDescription = getPaymentDescription({
    service: servicesInView[0],
    selectedVariantsOptions,
    context,
  });

  const dateAndTime = selectedTime
    ? formatRfcTimeStringToDateAndTimeView(
        selectedTime,
        dateRegionalSettingsLocale,
      )
    : '';

  const bookingPreferences = isDateAndTimeViewMode
    ? []
    : getBookingPreferencesForSelectedTime({
        selectableSlotsAtSelectedTime: selectableSlotsAtSelectedTime ?? [],
        selectedBookingPreferences,
        calendarErrors,
        context,
        state,
      });
  sortBookingPreferencesOptions(bookingPreferences);

  const staffMemberPreferences = bookingPreferences.find(
    (preference) => preference.key === Preference.STAFF_MEMBER,
  );

  if (
    experiments.enabled('specs.bookings.calendarAutoAssignResource') &&
    canAutoAssignStaff(state) &&
    staffMemberPreferences?.options?.length &&
    staffMemberPreferences.options.length > 1
  ) {
    staffMemberPreferences.options.unshift({
      id: DO_NOT_CARE_STAFF,
      value: t('app.preferences.any-available-staff'),
      isSelectable: true,
    });
  }

  return {
    serviceName,
    paymentDescription,
    dateAndTime,
    videoConferenceBadgeText,
    preferences: isDateAndTimeViewMode
      ? undefined
      : {
          bookingPreferences: getBookingPreferencesOptionsWithEnrichedValue({
            bookingPreferences,
            waitlistIndicationText: getContent({
              settingsParam: settingsParams.waitlistIndication,
              translationKey: 'app.settings.defaults.waitlist',
            }),
            t,
          }),
          clearText: settings.get(settingsParams.bookingDetailsClearText),
          titleText: getContent({
            settingsParam: settingsParams.preferencesTitle,
            translationKey:
              'app.settings.defaults.booking-details.preferences.title',
          }),
        },
    alert,
  };
}

export function createDummyBookingDetailsViewModel({
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): BookingDetailsViewModel {
  const { t, settings, getContent, settingsParams } = context;

  return {
    videoConferenceBadgeText: settings.get(
      settingsParams.videoConferenceBadgeVisibility,
    )
      ? getVideoConferencingBadge(context)
      : '',
    serviceName: t('dummy-content.service.name'),
    paymentDescription: t('dummy-content.service.price'),
    preferences: {
      bookingPreferences: [
        {
          error: {
            key: CalendarErrors.NO_SELECTED_LOCATION_ERROR,
            message: '',
          },
          key: Preference.LOCATION,
          placeholder: '',
          options: [
            {
              value: getContent({
                settingsParam: settingsParams.locationLabel,
                translationKey: 'dummy-content.service.location',
              }),
            },
          ],
        },
        {
          error: {
            key: CalendarErrors.NO_SELECTED_STAFF_MEMBER_ERROR,
            message: '',
          },
          key: Preference.STAFF_MEMBER,
          placeholder: '',
          options: [
            {
              value: getContent({
                settingsParam: settingsParams.staffMemberLabel,
                translationKey: 'dummy-content.service.staff',
              }),
            },
          ],
        },
        {
          error: {
            key: CalendarErrors.NO_SELECTED_DURATION_ERROR,
            message: '',
          },
          key: Preference.DURATION,
          placeholder: '',
          options: [
            {
              value: getContent({
                settingsParam: settingsParams.durationLabel,
                translationKey: 'dummy-content.service.duration',
              }),
            },
          ],
        },
      ],
      clearText: settings.get(settingsParams.bookingDetailsClearText),
      titleText: getContent({
        settingsParam: settingsParams.preferencesTitle,
        translationKey:
          'app.settings.defaults.booking-details.preferences.title',
      }),
    },
  };
}

const getBookingPreferencesOptionsWithEnrichedValue = ({
  bookingPreferences,
  waitlistIndicationText,
  t,
}: {
  bookingPreferences: BookingPreference[];
  waitlistIndicationText: string;
  t: TFunction;
}): BookingPreference[] => {
  return bookingPreferences.map((bookingPreference: BookingPreference) => {
    let options: BookingPreferenceOption[];
    if (bookingPreference.options.length > 1) {
      options = bookingPreference.options.map(
        (bookingPreferenceOption: BookingPreferenceOption) => {
          const valueWithWaitlistOnlyIndication = t(
            'app.booking-details.dropdowns.option-with-waitlist-only',
            {
              option: bookingPreferenceOption.value,
              waitlistOnly: waitlistIndicationText,
            },
          );
          return {
            ...bookingPreferenceOption,
            value: bookingPreferenceOption.isWithWaitingList
              ? valueWithWaitlistOnlyIndication
              : bookingPreferenceOption.value,
          };
        },
      );
    } else {
      options = bookingPreference.options;
    }

    return {
      ...bookingPreference,
      options,
    };
  });
};

const sortBookingPreferencesOptions = (
  bookingPreferences: BookingPreference[],
) => {
  bookingPreferences.forEach((preference) =>
    preference.options.sort((firstOption, secondOption) =>
      firstOption.value!.localeCompare(secondOption.value!),
    ),
  );
};
