import { CalendarContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { currentLanguage } from '../../../../utils/currentLanguage/currentLanguage';
import { CalendarState } from '../../controller';

export type OnLoginClick = () => void;

export function createOnLoginClickAction({
  context: {
    flowAPI: {
      experiments,
      environment,
      controllerConfig: { wixCodeApi },
    },
    wixSdkAdapter,
  },
}: ActionFactoryParams<CalendarState, CalendarContext>): OnLoginClick {
  return () => {
    if (!environment.isPreview) {
      if (experiments.enabled('specs.bookings.useOneLinerInsteadOfWixSDK')) {
        wixCodeApi.user.promptLogin({
          mode: 'login',
          lang: currentLanguage(wixCodeApi),
        });
      } else {
        wixSdkAdapter.promptUserLogin();
      }
    }
  };
}
